import React from "react";
import user from "../../../Assets/Icons/user.png"
import titan from "../../../Assets/Icons/titan-dp.png"
import Lottie from "lottie-react";
import anim from "../../../json/animation_lkkna7lf.json";
import { useStoreState } from "easy-peasy";

const InlineMessageComponent = (props) => {
    const messageHistory = useStoreState(state => state.messageHistory);

    return (
        <div >
            {messageHistory.length === 0 ?
                <div className="InlineMessageComponent">
                    <img className="avatar" alt="" src={titan} />
                    <div
                        className="message-container-wrapper">

                        <div className="message-container message-container-invert ">
                            <div className="sender">Titan</div>
                            {localStorage.getItem("initial_message")}
                            <br /><br />
                            *I am still learning. I may sometimes imagine things or give inaccurate info, but I promise I'm always coming from a good place. Let's just have some fun!
                        </div>
                    </div>
                </div> : <>
                    <div className="InlineMessageComponent">
                        <img className="avatar" alt="" src={user} />
                        <div
                            className="message-container-wrapper">

                            <div className="message-container">
                                <div className="sender">{localStorage.getItem("titan_name")}</div>
                                {props?.message?.user_input}</div>
                        </div>
                    </div>

                    <div className="InlineMessageComponent">
                        <img className="avatar" alt="" src={titan} />
                        <div
                            className="message-container-wrapper">
                            <div className="message-container">
                                <div className="sender">Titan</div>
                                {props?.message?.titan_response !== "" ?
                                    <div dangerouslySetInnerHTML={{ __html: props?.message?.titan_response }} /> :
                                    <Lottie animationData={anim} loop={true} style={{ width: 150, height: 100, marginTop: "-40px" }} />}
                            </div>

                        </div>
                    </div>
                </>}


        </div>

    )

}
export default InlineMessageComponent
import React, { useEffect, useState } from "react";
import "./HomeStyles.scss"
import BottomComponent from "../../Components/BottomComponent/BottomComponent";
import Lottie from "lottie-react";
import anim from "../../json/animation_lkkpqpaq.json";
import ChatPage from "../ChatPage/ChatPage";
import { useStoreActions } from "easy-peasy";
import messages from "../welcomeMessages";

const Home = () => {
    //state
    const [page, setPage] = useState(1)
    //store actions
    const loginHandler = useStoreActions(actions => actions.loginHandler);
    const getAllQuestions = useStoreActions(actions => actions.getAllQuestions);


    const array = messages
    const randomIndex = Math.floor(Math.random() * array.length);


    useEffect(() => {
        if (localStorage.getItem("titan_email")) {
            let data = {
                "name": localStorage.getItem("titan_name"),
                "email": localStorage.getItem("titan_email")
            }
            handleLogin(data)
            if (!localStorage.getItem("initial_message")) {
                localStorage.setItem("initial_message", array[randomIndex])
            }
            setTimeout(() => {
                setPage(2)
            }, 3900);
        }
    }, [])
    const getChatPage = () => {
        return <ChatPage />
    }
    const handleLogin = async (payload) => {
        await loginHandler(payload).then(async (success) => {
            if (success) {
                if (localStorage.getItem("titan_questions_done") === "false") {
                    await getAllQuestions()
                }
            }
        })
    }
    const getHomePage = () => {
        return (
            <div className="Home">
                <div className="home-container">
                    <Lottie animationData={anim} loop={true} speed={1} style={{ width: "100vw" }} />
                    <div className="welcome-text">Welcome! </div>
                    <div className="name">{localStorage.getItem("titan_name")}</div>
                </div>
                <BottomComponent />
            </div>
        )
    }

    return (
        page === 1 ? getHomePage() : getChatPage()
    )

}
export default Home











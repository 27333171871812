import React, { useState } from "react";
import success from "../../../../Assets/Images/success.png";
import close from "../../../../Assets/Icons/close.png"
import share from "../../../../Assets/Icons/share.png"
import scoreImg from "../../../../Assets/Icons/score.png"
import "./PageStyles.scss"
import { useStoreActions, useStoreState } from "easy-peasy";
import Lottie from "lottie-react";
import anim from "../../../../json/celeb.json";

const SuccessPage = (props) => {
    //state
    const [copied, setCopied] = useState(false)

    // store state
    const score = useStoreState(state => state.score);

    //store action
    const setIsQuestionsDone = useStoreActions(actions => actions.setIsQuestionsDone);
    let text = 
    `Can you beat my Titan score? 😏
    🌟 Score: ${score} 🌟
    https://titans.zapmail.app
    #GalleTitans #Zapmail #LPL2023`
    //get the height of the page
    const getHeight = () => {
        if (document.getElementById("chat-navbar-id")) {
            let h = document.getElementById("chat-navbar-id").clientHeight + 70;

            if (window.screen.width < 600) {
                return `calc(100dvh - ${h}px)`;
            } else {
                return `calc(100vh - ${h}px)`;
            }
        }
    };
    const copyToClipboard = (text) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();

        try {
            document.execCommand('copy');
            console.log('Text copied to clipboard successfully!');
        } catch (error) {
            console.error('Error copying text to clipboard:', error);
        }

        document.body.removeChild(textarea);
    };
    return (
        <div className="SuccessPage" style={{ height: getHeight() }}>
            <div className={window.screen.width<600?"image-container":"image-container-web"}>
                <img alt="" src={success} className="img-succ"/>
            </div>
            <div className="close-icon" onClick={() => {
                setIsQuestionsDone(true)
                localStorage.setItem("titan_questions_done", true)
            }}>
                <img alt="" src={close} className="close" />
            </div>
            <div className="congrats-text">Congratulations !</div>
            <div className="score-text">Your Runs</div>
            <div className="score">{score}</div>
            <div className="btn-container-success">
                <div className="score-btn-container ">
                    <div className="score-btn" onClick={() => {
                        props.setViewScoreBoard(true)
                    }}>Score Board
                        <img alt="" src={scoreImg} className="score-icon" /></div>
                </div>

                <div className="share-btn-container">
                    <div className="share-btn" onClick={async () => {
                        copyToClipboard(text);
                        setCopied(true)
                        setTimeout(() => {
                            setCopied(false)
                        }, 800);
                    }}>Copy & Share <img alt="" src={share} className="share" /></div>
                    {copied ? <div className="copied">Copied</div> : ""}
                </div>

            </div>

            <div className="succe-lottie">
                <Lottie animationData={anim} loop={true} style={{ height: getHeight(), width: "100vw", marginLeft: "-20px", zIndex: 0, position: "absolute" }} />
            </div>
        </div>
    )

}
export default SuccessPage
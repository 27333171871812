import React from "react";
import "./BottomComponentStyles.scss"
import logo from "../../Assets/Images/logo.png"

const BottomComponent = () => {
    return (
        <div className="BottomComponent">
            <div className="text">Powered by</div>
            <a href="https://zapmail.app?ref=titan" target="_blank" rel="noreferrer"><img alt="" src={logo} className="logo" /></a>
        </div>
    )
}
export default BottomComponent
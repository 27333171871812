import React, { useEffect } from 'react';
import send from "../../../Assets/Icons/send-white.png"
import { useStoreActions, useStoreState } from "easy-peasy";

const ChatInput = (props) => {
    //store actions
    const senMessage = useStoreActions(actions => actions.senMessage);
    const setMessageHistory = useStoreActions(actions => actions.setMessageHistory);
    // store state
    const messageHistory = useStoreState(state => state.messageHistory);
    //handle input value
    const handleChange = (e) => {
        props.setValue(e.target.value);
    };
    //adjust text are height
    const adjustInputHeight = (e) => {
        e.target.style.height = 'auto'; // Reset the height to auto
        e.target.style.height = `${e.target.scrollHeight}px`; // Set the height to the content's scroll height
    };
    const handleSendClick = () => {
        if (props.value.replace(/\s/g, '').length) {
            let data = {
                "user_input": props.value,
                "titan_response": ""
            }
            setMessageHistory([...messageHistory, data])
            senMessage(props.value)
            props.setValue("")

        } else {
            props.setValue("")
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSendClick()
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props.value]);
    return (
        <div className='ChatInput' id='chatinput-id'>
            <div className='input-container'>
                <div className='input-wrapper'>
                    <textarea
                        id="auto-expanding-input"
                        rows="1"
                        className='input-styles'
                        placeholder={`Send a message ...`}
                        onChange={handleChange}
                        onInput={adjustInputHeight}
                        value={props.value}
                    />
                    <div
                        className='send-styles' >
                        <img src={send} alt=''
                            className='send-icon'
                            onClick={handleSendClick} />
                    </div>
                </div>
            </div>
        </div>
    )

}
export default ChatInput
import React, { useEffect, useState } from "react";
import "./TriviaPageStyles.scss"
import { useStoreState, useStoreActions } from "easy-peasy";


const TriviaPage = (props) => {
    //state
    const [selected, setSelected] = useState("")
    const [isStarted, setIsStarted] = useState(false)
    // store state
    const currentQuestion = useStoreState(state => state.currentQuestion);
    const answers = useStoreState(state => state.answers);
    const questions = useStoreState(state => state.questions);


    //store actions
    const setAnswers = useStoreActions(actions => actions.setAnswers);
    const setUpdateQuestion = useStoreActions(actions => actions.setUpdateQuestion);
    const sendAnswers = useStoreActions(actions => actions.sendAnswers);



    useEffect(() => {
        selectedAnswer()
    }, [currentQuestion])


    //get the height of the page
    const getHeight = () => {
        if (document.getElementById("chat-navbar-id")) {
            let h = document.getElementById("chat-navbar-id").clientHeight + 70;

            if (window.screen.width < 600) {
                return `calc(100dvh - ${h}px)`;
            } else {
                return `calc(100vh - ${h}px)`;
            }
        }
    };
    // set selected answer to the state
    const selectedAnswer = () => {
        let current = answers.find(x => x.questionNum === currentQuestion.questionNum)
        if (current) {
            setSelected(current.answer)
        } else {
            setSelected("")
        }
    }
    // function to handle the update the answer according to the question
    const handleAnswerClick = (selectedAnswerNum) => {
        setSelected(selectedAnswerNum)
        let existingAnswer = answers.find(x => x.questionNum === currentQuestion.questionNum)
        if (existingAnswer) {
            existingAnswer.answer = selectedAnswerNum
        } else {
            let data = {
                "questionNum": currentQuestion.questionNum,
                "answer": selectedAnswerNum
            }
            setAnswers([...answers, data])
        }

    }
    const isAnswered = () => {
        let isFound = answers.some(x => x.questionNum === currentQuestion.questionNum)
        return isFound
    }
    return (
        questions.length > 0 &&
        (isStarted ?
            <div className="TriviaPage" style={{ height: getHeight() }}>
                <div className="question-wrapper">{currentQuestion?.questionNum}.{currentQuestion?.question}</div>
                <div className="answers-container">
                    {currentQuestion?.answers?.map((answer, key) => {
                        return (
                            <div className={`answer-wrapper ${selected === answer ? "selected" : ""}`} key={key} onClick={() => {
                                handleAnswerClick(answer)
                            }}>{key + 1}. {answer}</div>
                        )
                    })}
                </div>
                <div className="btn-container">
                    {currentQuestion?.questionNum !== 1 ?
                        <div className="previous-btn" onClick={() => {
                            setUpdateQuestion(currentQuestion?.questionNum - 2)

                        }}>Previous</div> : <div className="empty-btn" />}

                    {isAnswered() ?
                        <div className="next-btn" onClick={async () => {
                            if (questions?.length === currentQuestion?.questionNum) {
                                sendAnswers().then((success) => {
                                    if (success) {
                                        props.setCompleted(true)
                                    } else {
                                        props.setCompleted(false)
                                    }
                                })
                            } else {
                                setUpdateQuestion(currentQuestion.questionNum)
                            }

                        }}
                        >{questions.length === currentQuestion.questionNum ? "Submit" : "Next"}</div> : ""}
                </div>
            </div> :
            <div className="TiriviaWelcomePage" style={{ height: getHeight() }}>
                <div className="welcome-content">
                    <div className="header">Welcome  to<br/> the Cricket Trivia<br/> Challenge! 🏏</div>
                    <div className="content">Answer right, score big, and
                        you could win match tickets and exciting prizes. Get ready to hit it
                        out of the park! 🏆🚀</div>
                    <div className="start-btn" onClick={() => {
                        setIsStarted(true)
                    }}>START GAME</div>

                </div>

            </div>

        )
    )

}
export default TriviaPage
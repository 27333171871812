import React, { useEffect, useState } from "react";
import "./ChatPageStyles.scss"
import ChatNavbar from "./ChatComponents/ChatNavbar";
import ChatInput from "./ChatComponents/ChatInput";
import ChatContainer from "./ChatComponents/ChatContainer";
import BottomComponent from "../../Components/BottomComponent/BottomComponent";
import TriviaPage from "./TriviaPage/TriviaPage";
import SuccessPage from "./TriviaPage/Celebration/SuccessPage";
import ErrorPage from "./TriviaPage/Celebration/ErrorPage";
import { useStoreState, useStoreActions } from "easy-peasy";
import ScoreBoard from "./TriviaPage/ScoreBoard/ScoreBoard";

const ChatPage = () => {
    //state
    const [value, setValue] = useState("")
    const [height, setHeight] = useState(0);
    const [selectedTab, setSelectedTab] = useState(1)
    const [completed, setCompleted] = useState(false)
    const [viewScoreBoard, setViewScoreBoard] = useState(false)

    // store state
    const isQuestionsDone = useStoreState(state => state.isQuestionsDone);
    const messageHistory = useStoreState(state => state.messageHistory);
    const answers = useStoreState(state => state.answers);
    //store actions
    const senMessage = useStoreActions(actions => actions.senMessage);
    const setMessageHistory = useStoreActions(actions => actions.setMessageHistory);

    // prompt

    const prompts = [
        "ඔයා කවුද?",
        "Next match ? 🏏",
        "What's Galle Titans ?",
        "Do a Cheer 🎉"
    ]

    const handleSendClick = (value) => {
        let data = {
            "user_input": value,
            "titan_response": ""
        }
        setMessageHistory([...messageHistory, data])
        senMessage(value)
    }

    const getHeight = () => {
        if (document.getElementById("chatinput-id") && document.getElementById("chat-navbar-id") && document.getElementById("chatprompt-id")) {
            let h = document.getElementById("chatinput-id").clientHeight +
                document.getElementById("chat-navbar-id").clientHeight +
                document.getElementById("chatprompt-id").clientHeight;
            if (window.screen.width < 600) {
                return `calc(100dvh - ${h}px)`;
            } else {
                return `calc(100vh - ${h}px)`;
            }
        }
    };
    const getPromptBottom = () => {
        if (document.getElementById("chatinput-id")) {
            let h = document.getElementById("chatinput-id").clientHeight + 13;
            return h
        }
    }
    const getChatPage = () => {
        return (
            <div>
                <ChatContainer height={height} />
                <div
                    id="chatprompt-id"
                    className="ChatSuggest"
                    style={{ bottom: getPromptBottom() }}>
                    {prompts.map((prompt, key) => {
                        return (
                            <div className="prompt" key={key} onClick={() => {
                                handleSendClick(prompt)
                            }}>{prompt}</div>
                        )
                    })}
                </div>
                <ChatInput
                    value={value}
                    setValue={setValue} />

            </div>
        )
    }
    const getTrivia = () => {
        return (
            viewScoreBoard || answers.length === 0 ?
                <ScoreBoard setViewScoreBoard={setViewScoreBoard} />
                :
                isQuestionsDone ?
                    <ErrorPage setViewScoreBoard={setViewScoreBoard} />
                    : !completed ?
                        <TriviaPage setCompleted={setCompleted} />
                        :
                        <SuccessPage setViewScoreBoard={setViewScoreBoard} />

        )
    }
    useEffect(() => {
        setHeight(getHeight)
    }, [value])
    return (
        <div className="ChatPage">
            <ChatNavbar
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab} />
            {selectedTab === 1 ?
                getChatPage()
                : getTrivia()}

            <BottomComponent />

        </div>)

}
export default ChatPage
import React, { useState } from "react";
import error from "../../../../Assets/Images/trophy.png";
import share from "../../../../Assets/Icons/share.png"
import scoreImg from "../../../../Assets/Icons/score.png"
import "./PageStyles.scss"
import { useStoreState } from "easy-peasy";

const ErrorPage = (props) => {
    //state
    const [copied, setCopied] = useState(false)
    // store state
    const score = useStoreState(state => state.score);

    let text =
        `Can you beat my Titan score? 😏
    🌟 Score:  ${score} 🌟
    https://titans.zapmail.app
    #GalleTitans #Zapmail #LPL2023`
    const copyToClipboard = (text) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();

        try {
            document.execCommand('copy');
            console.log('Text copied to clipboard successfully!');
        } catch (error) {
            console.error('Error copying text to clipboard:', error);
        }

        document.body.removeChild(textarea);
    };
    const getHeight = () => {
        if (document.getElementById("chat-navbar-id")) {
            let h = document.getElementById("chat-navbar-id").clientHeight + 70;

            if (window.screen.width < 600) {
                return `calc(100dvh - ${h}px)`;
            } else {
                return `calc(100vh - ${h}px)`;
            }
        }
    };
    return (
        <div className="SuccessPage" style={{ height: getHeight() }}>
            <div className={window.screen.width < 600 ? "error-image-container" : "error-image-container-web"}>
                <img alt="" src={error} className="err-img" />
            </div>
            <div className="congrats-text">Innings break !</div>
            <div className="sub-texts">🏏 Your innings for today has come to a close, but fret not, the next match day awaits your presence at the crease! 🏆🌟</div>
            <div className="score-text">Runs you scored today</div>
            <div className="score">{score}</div>
            <div className="btn-container-success">
                <div className="score-btn-container">
                    <div className="score-btn" onClick={() => {
                        props.setViewScoreBoard(true)
                    }}>Score Board
                        <img alt="" src={scoreImg} className="score-icon" /></div>
                </div>
                <div className="share-btn-container">
                    <div className="share-btn" onClick={async () => {
                        copyToClipboard(text);
                        setCopied(true)
                        setTimeout(() => {
                            setCopied(false)
                        }, 800);
                    }}>Copy & Share <img alt="" src={share} className="share" /></div>
                    {copied ? <div className="copied">Copied</div> : ""}
                </div>
            </div>
        </div>
    )

}
export default ErrorPage
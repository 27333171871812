export const services = () => {
    const getScoreDate = () => {
        const currentDate = new Date();
        if (currentDate < new Date("2023-08-15") && currentDate > new Date("2023-08-13")) {
            return 8;
        } else if (currentDate < new Date("2023-08-13") && currentDate > new Date("2023-08-11")) {
            return 7;
        } else if (currentDate < new Date("2023-08-11") && currentDate > new Date("2023-08-08")) {
            return 6;
        } else if (currentDate < new Date("2023-08-08") && currentDate > new Date("2023-08-07")) {
            return 5;
        } else if (currentDate < new Date("2023-08-07") && currentDate > new Date("2023-08-04")) {
            return 4;
        } else if (currentDate < new Date("2023-08-04") && currentDate > new Date("2023-08-01")) {
            return 3;
        } else if (currentDate < new Date("2023-08-01") && currentDate > new Date("2023-07-31")) {
            return 2;
        } else if (currentDate < new Date("2023-07-31")) {
            return 1;
        } else {
            // If the current_date is beyond the specified ranges, you can handle it here.
            return -1; // or return an appropriate value
        }
    }

    return {
        getScoreDate
    };
}
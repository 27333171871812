import { action, thunk } from 'easy-peasy';
import config from '../config';
import axios from 'axios';
import { services } from '../services/services';
const store = {

    //state
    messageHistory: [],
    questions: [],
    currentQuestion: {},
    answers: [],
    score: 0,
    isQuestionsDone: localStorage.getItem("titan_questions_done"),
    ranking: [],

    //actions
    setMessageHistory: action((state, messageHistory) => {
        state.messageHistory = messageHistory;
    }),
    updateMessageHistory: action((state, message) => {
        state.messageHistory[state.messageHistory.length - 1].titan_response = message
    }),
    setQuestions: action((state, questions) => {
        state.questions = questions;
    }),
    setCurrentQuestions: action((state, currentQuestion) => {
        state.currentQuestion = currentQuestion;
    }),
    setAnswers: action((state, answers) => {
        state.answers = answers;
    }),
    setUpdateQuestion: action((state, questionNum) => {
        state.currentQuestion = state.questions[questionNum];
    }),
    setScore: action((state, score) => {
        state.score = score;
    }),
    setIsQuestionsDone: action((state, isQuestionsDone) => {
        state.isQuestionsDone = isQuestionsDone;
    }),
    setRanking: action((state, ranking) => {
        state.ranking = ranking;
    }),

    //api
    aiRestHelper: thunk(async (actions, payload) => {
        return new Promise(async resolve => {
            let defaultHeaders = {
                'Access-Control-Allow-Origin': '*',
            };
            await aiApi
                .request({
                    url: payload.url,
                    method: payload.method,
                    data: payload.data ? payload.data : null,
                    headers: {
                        ...aiApi.defaults.headers,
                        ...defaultHeaders,
                    },
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }),
    //thunks api calls

    //login api
    loginHandler: thunk(async (actions, payload) => {
        return new Promise(async resolve => {
            let url = '/userLogin';
            await aiApi
                .post(url, payload)
                .then(async response => {
                    if (response.status === 200 || response.status === 201) {
                        console.log(response, "response");
                        localStorage.setItem("titan_name", payload.name)
                        localStorage.setItem("titan_email", payload.email)
                        if (response.data.history) {
                            actions.setMessageHistory(response.data.history)
                        } else {
                            actions.setMessageHistory([])
                        }
                        let scoreDate = services().getScoreDate()

                        let answerForTheDay = `answers_${scoreDate}`
                        if (response.data[answerForTheDay]) {
                            localStorage.setItem("titan_questions_done", true)
                            actions.setIsQuestionsDone(true)
                            if (response.data[`user_score_${scoreDate}`]) {
                                actions.setScore(response.data[`user_score_${scoreDate}`])
                            } else {
                                actions.setScore(0)

                            }
                        } else {
                            localStorage.setItem("titan_questions_done", false)
                            actions.setIsQuestionsDone(false)
                        }
                        resolve(true)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }),

    //send Message 
    senMessage: thunk(async (actions, payload) => {
        return new Promise(async resolve => {
            let url = '/titanChat';
            let data = {
                "name": localStorage.getItem("titan_name"),
                "email": localStorage.getItem("titan_email"),
                "question": payload
            }
            await aiApi
                .post(url, data)
                .then(async response => {
                    if (response.status === 200) {
                        actions.updateMessageHistory(response.data)
                        resolve(true)
                    }
                })
                .catch(error => {
                    const array =
                        ["Load wenawa poddak inna !",
                            "Out for tea! Well be back soon!!",
                            "Dan athi,Mata hathi ;|"]
                    const randomIndex = Math.floor(Math.random() * array.length);
                    actions.updateMessageHistory(array[randomIndex])
                    console.log(error);
                });
        });
    }),
    //get all questions
    getAllQuestions: thunk(async (actions) => {
        return new Promise(async resolve => {
            let url = '/getAllQuestions';
            await aiApi
                .post(url, {})
                .then(async response => {
                    if (response.status === 200) {
                        console.log(response.data.reverse(), "response.data.reverse()");
                        actions.setQuestions(response.data.reverse())
                        actions.setCurrentQuestions(response.data.reverse()[0])
                        resolve(true)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }),
    //send Message 
    sendAnswers: thunk(async (actions, payload, { getState }) => {
        return new Promise(async resolve => {
            let state = getState()
            let url = '/sendAnswers';
            let data = {
                "name": localStorage.getItem("titan_name"),
                "email": localStorage.getItem("titan_email"),
                "answers": state.answers.reverse()
            }
            const defaultHeaders = {
                "titan-api-key": config.apiKey,
            };
            await aiApi
                .request({
                    url: url,
                    method: "POST",
                    data: data,
                    headers: {
                        ...aiApi.defaults.headers,
                        ...defaultHeaders,
                    },
                })
                .then(async response => {
                    if (response.status === 200) {
                        actions.setScore(response.data.user_score)
                        resolve(true)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }),
    //get leader board
    getLeaderboard: thunk(async (actions) => {
        return new Promise(async resolve => {
            let url = '/getLeaderboard';
            await aiApi
                .get(url, {})
                .then(async response => {
                    if (response.status === 200) {
                        actions.setRanking(response.data)
                        resolve(true)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }),

}
const aiApi = axios.create({
    baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.aiServer}`,
});
export default store
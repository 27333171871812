import React from "react";
import image from "../../../Assets/Images/titan.png"
import question from "../../../Assets/Icons/question.png"


const ChatNavbar = (props) => {

    return (
        <div className="ChatNavbar" id="chat-navbar-id">
            <div className="tab-conatiner">
                <div className={`tab ${props.selectedTab === 1 ? "selected" : ""}`} onClick={() => {
                    props.setSelectedTab(1)
                }}>
                    <img alt="" src={image} className="icon" />
                    Chat with titan</div>
                <div className={`tab ${props.selectedTab === 2 ? "selected" : ""}`} onClick={() => {
                    props.setSelectedTab(2)
                }}>
                    <img alt="" src={question} className="icon-quest" />
                    Trivia</div>
            </div>
        </div>)

}
export default ChatNavbar
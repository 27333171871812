import React, { forwardRef } from "react";
import "./ButtonStyles.scss";

const Button = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className={`submit-button ${props.loading ? " is-loading" : ""}`}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{
        cursor: props.notAllowed ? "not-allowed" : "pointer",
      }}
    >
      {!props.loading ? props.buttonText : <div className="loader" />}
    </div>
  );
});
export default Button;

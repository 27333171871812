import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginStyles.scss";
import UsernameInput from "../../Components/UsernameInput/UsernameInput";
import Button from "../../Components/Button/Button";
import BottomComponent from "../../Components/BottomComponent/BottomComponent";
import { useStoreActions } from "easy-peasy";
import titan from "../../Assets/Images/titan.png"

const Login = () => {
  //states
  const [userName, setUserName] = useState("");
  const [fullname, setFullname] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [fullnameError, setFullnameError] = useState(false);
  const [isUsernameFocus, setIsUsernameFocus] = useState(false);
  const [isFullnameFocus, setIsFullnameFocus] = useState(false);
  const [userNameErrorText, setUserNameErrorText] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isAppLoading, setIsAppLoading] = useState(true)

  //navigation
  const navigate = useNavigate();
  //button refs
  const loginBtn = useRef(null);
  //store actions
  const loginHandler = useStoreActions(actions => actions.loginHandler);
  const getAllQuestions = useStoreActions(actions => actions.getAllQuestions);




  useEffect(() => {
    if (localStorage.getItem("titan_email")) {
      let data = {
        "name": localStorage.getItem("titan_name"),
        "email": localStorage.getItem("titan_email")
      }
      handleLogin(data)
    } else {
      setIsAppLoading(false)
    }
    // eslint-disable-next-line 
  }, []);
  const buttonClick = () => {
    if (userName !== "" && fullname !== "") {
      if (!validateEmail()) {
        setUserNameErrorText("*Invalid email")
        setUserNameError(true)
      } else {
        removeFocus()
        setIsLoading(true)
        let data = {
          "name": fullname,
          "email": userName
        }
        handleLogin(data)
      }
    } if (userName === "") {
      setUserNameErrorText("*Required")
      setUserNameError(true);
    } if (fullname === "") {
      setFullnameError(true)
    }
  }
  const handleLogin = async (payload) => {
    await loginHandler(payload).then(async (success) => {
      if (success) {
        setIsLoading(false)
        if (localStorage.getItem("titan_questions_done") === "false") {
          await getAllQuestions()
        }
        navigate("/home")
      }
    })
  }

  const handleUsername = (e) => {
    let userNameValue = e.target.value.trim();
    if (userNameError) {
      if (userNameValue !== "") {
        setUserNameError(false);
      }
    }
    setUserName(userNameValue);
  };
  const handleFullname = (e) => {
    let fullnameValue = e.target.value;
    setFullnameError(false);
    setFullname(fullnameValue);
  };
  const removeFocus = () => {
    setIsUsernameFocus(false);
    setIsFullnameFocus(false);
  };
  const validateEmail = () => {
    return userName
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const contentToShow = () => {
    return (
      <div className="login">
        <div className="_loginContainer">
          <div className="form-wrapper">
            <div className="header-container">
              <img alt="" src={titan} className="logo" />
            </div>
            <div className="loginwrapper">
              <div className="input-wrapper">
                <UsernameInput
                  type="text"
                  isUsernameFocus={isFullnameFocus}
                  placeholder={isFullnameFocus ? "" : "Name"}
                  value={fullname}
                  onChange={(e) => {
                    handleFullname(e);
                  }}
                  userNameError={fullnameError}
                  onFocus={() => {
                    setIsFullnameFocus(true);
                    setTimeout(() => window.scrollTo(0, 100), 0);
                  }}
                  onBlur={() => setIsFullnameFocus(false)}
                  name="username"
                />
                {fullnameError ? <div className="form-fail">*Required</div> : ""}
              </div>
              <div className="input-wrapper">
                <UsernameInput
                  type="text"
                  isUsernameFocus={isUsernameFocus}
                  placeholder={isUsernameFocus ? "" : "Email"}
                  value={userName}
                  onChange={(e) => {
                    handleUsername(e);
                  }}
                  userNameError={userNameError}
                  onFocus={() => {
                    setIsUsernameFocus(true);
                    setTimeout(() => window.scrollTo(0, 100), 0);
                  }}
                  onBlur={() => setIsUsernameFocus(false)}
                  name="username"
                />
                {userNameError ? <div className="form-fail">{userNameErrorText}</div> : ""}
              </div>
              <div className="note-text">*This email will be used to contact you for prizes</div>
              <div className="margin-top-32" />

              <Button
                ref={loginBtn}
                loading={isLoading}
                onClick={buttonClick}
                buttonText="Submit"
              />
            </div>
          </div>
        </div>
        <BottomComponent />
      </div>
    );
  };
  const loadingPage = () => {
    return <div className="login" />
  }

  return (
    <>
      {isAppLoading ? loadingPage() : contentToShow()}
    </>
  );
};
export default Login;

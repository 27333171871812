import React, { useEffect } from "react";
import InlineMessageComponent from "./InlineMessageComponent";
import { useStoreState } from "easy-peasy";

const ChatContainer = (props) => {
    //store state
    const messageHistory = useStoreState(state => state.messageHistory);



    const scrollToBottom = () => {
        const myDiv = document.getElementById("myDiv");
        myDiv.scrollTop = myDiv.scrollHeight;
    }

    useEffect(() => {
        // Call the function to scroll to the bottom of the div
        scrollToBottom();
    }, [messageHistory])


    return (
        <div id="myDiv" className="ChatContainer" style={{ height: props.height }}>
            <div className="disclaimer"> I may sometimes imagine things or give inaccurate info, but I promise I'm always coming from a good place</div>
            {messageHistory.length === 0 && <InlineMessageComponent message={props.initialMessage} />}
            {messageHistory?.map((message, key) => {
                return (
                    <InlineMessageComponent
                        message={message}
                        key={key}
                    />

                )
            })}
        </div>
    )

}
export default ChatContainer
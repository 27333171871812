import React, { useEffect } from "react"
import "./ScoreBoardStyles.scss"
import close from "../../../../Assets/Icons/close.png"
import gold from "../../../../Assets/Icons/gold.png"
import silver from "../../../../Assets/Icons/silver.png"
import bronze from "../../../../Assets/Icons/bronze.png"
import { useStoreActions, useStoreState } from "easy-peasy";


const ScoreBoard = (props) => {
    //store action
    const setIsQuestionsDone = useStoreActions(actions => actions.setIsQuestionsDone);
    const getLeaderboard = useStoreActions(actions => actions.getLeaderboard);
    const ranking = useStoreState(state => state.ranking);

    useEffect(() => {
        getData()
    }, [])
    const getData = async () => {
        await getLeaderboard()
    }


    //get the height of the page
    const getHeight = () => {
        if (document.getElementById("chat-navbar-id")) {
            let h = document.getElementById("chat-navbar-id").clientHeight + 70;

            if (window.screen.width < 600) {
                return `calc(100dvh - ${h}px)`;
            } else {
                return `calc(100vh - ${h}px)`;
            }
        }
    };
    const getScrollHeight = () => {
        if (document.getElementById("chat-navbar-id") && document.getElementById("score-header")) {
            let h = document.getElementById("chat-navbar-id").clientHeight + document.getElementById("score-header").clientHeight + 150;
            if (window.screen.width < 600) {
                return `calc(100dvh - ${h}px)`;
            } else {
                return `calc(100vh - ${h}px)`;
            }
        }
    };
    return (
        <div className="ScoreBoard" style={{ height: getHeight() }}>
            <div className="header" id="score-header">Top 25 Players
                <div className="close-icon" onClick={() => {
                    props.setViewScoreBoard(false)
                    setIsQuestionsDone(true)
                    localStorage.setItem("titan_questions_done", true)
                }}>
                    <img alt="" src={close} className="close" />
                </div></div>
            <div className="score-board" style={{ height: getScrollHeight() }}>
                <div className="rank-container">
                    <div className="medal-container"><img alt="" src={gold} className="gold-medal" /></div>
                    <div className="name">
                        1.{ranking[0]?.name.split(' ').slice(0, 2).join(' ')}</div>
                    <div className="score">{ranking[0]?.score}</div>
                </div>

                <div className="rank-container rank-silver ">
                    <div className="medal-container"> <img alt="" src={silver} className="gold-medal" /></div>
                    <div className="name">2.{ranking[1]?.name.split(' ').slice(0, 2).join(' ')}</div>
                    <div className="score">{ranking[1]?.score}</div>
                </div>
                <div className="rank-container rank-bronze">
                    <div className="medal-container"> <img alt="" src={bronze} className="gold-medal" /></div>
                    <div className="name">3.{ranking[2]?.name.split(' ').slice(0, 2).join(' ')}</div>
                    <div className="score">{ranking[2]?.score}</div>
                </div>
                <div className="rank-container rank-4">
                    <div className="medal-container" />
                    <div className="name"> 4.{ranking[3]?.name.split(' ').slice(0, 2).join(' ')}</div>
                    <div className="score">{ranking[3]?.score}</div>
                </div>
                <div className="rank-container rank-5">
                    <div className="medal-container" />
                    <div className="name">5.{ranking[4]?.name.split(' ').slice(0, 2).join(' ')}</div>
                    <div className="score">{ranking[4]?.score}</div>
                </div>
                {ranking.slice(5, 25).map((rank, key) => {
                    return (
                        <div className="rank-container rank-plus">
                            <div className="medal-container" />
                            <div className="name">{6 + key}.{rank.name.split(' ').slice(0, 2).join(' ')}</div>
                            <div className="score">{rank.score}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}
export default ScoreBoard